/*
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-16 14:50:36
 * @LastEditTime: 2020-12-28 10:37:52
 */
import { get, post } from '@utils/aRequestFather'

import { baseUrl } from '@/environment/index.js'


export default {
  // 获取商品类别
  getsplxData () {
    const url = baseUrl + '/business/splb'
    return get(url)
  },
  // 新增商铺
  createBusiness (data) {
    const url = baseUrl + '/business/create'
    return post(url, data)
  },
  // 更新商铺
  updateBusiness (data) {
    const url = baseUrl + '/business/update'
    return post(url, data)
  },
  // 商家信息初始化
  initBusiness (data) {
    const url = baseUrl + `/business/edit`
    return get(url, data)
  },
  // 商家商品类型列表 businessId
  getBusinessType (data) {
    const url = baseUrl + '/business/goodsType'
    return get(url, data)
  },
  // 商品类型列表 
  getBusinessTypeList () {
    const url = baseUrl + '/business/goodsTypeList'
    return get(url)
  },
  // 保存商品类型 post goodsTypeIds id
  saveBusinessType (data) {
    const url = baseUrl + '/business/saveGoodsType'
    return post(url, data)
  },
  // 删除商品类型 post businessGoodsTypeId
  delBusinessType (data) {
    const url = baseUrl + '/business/delGoodsType'
    return post(url, data)
  },
}