// import api from './index'
import {
    axios
} from './request'
import {
    baseUrl
} from '@/environment/index.js'
/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

//get
export function get(url, parameter) {
    return axios({
        url: url,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Session-Id': localStorage.getItem("sessionId") ? localStorage.getItem("sessionId") : "123",
        },
        withCredentials: true
    })
}


// post
export function post(url, parameter) {
    return axios({
        url: url,
        method: 'post',
        data: parameter,
        headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Session-Id': localStorage.getItem("sessionId") ? localStorage.getItem("sessionId") : "123",
        },
        withCredentials: true,
    })
}