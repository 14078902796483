import Vue from 'vue'
import toastModal from './toastModal.vue'

const toastModalBox = Vue.extend(toastModal)

toastModal.install = function (options = {content: '为了确保所有功能正常使用,请完成实名认证流程，谢谢！', stype: 1}) {
  let content = options.content
  let stype = options.stype
  let instance = new toastModalBox().$mount()  
  let len = document.getElementsByClassName('toast-modal').length
  document.body.appendChild(instance.$el)
  Vue.nextTick(() => {
    if (len == 0) {
      instance.visible = true
      instance.content = content 
      instance.stype = stype 
    }
   
  })
}

export default toastModal