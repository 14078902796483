import Vue from 'vue'

// 金钱过滤 ¥xx.xx
Vue.filter('moneyFormat', (value) => {
    return '¥' + Number(value).toFixed(2);
})

Vue.filter('stampTotime',function(stamp){
    if (!stamp || stamp == '0') return ''
    let setZero = (num) => {
        return num < 10 ? '0' + num : num;
    }
    let date = new Date(stamp * 1000);
    let Y = date.getFullYear() + '-';
    let M = setZero(date.getMonth() + 1) + '-';
    let D = setZero(date.getDate())+ ' ';
    let h = setZero(date.getHours()) + ':';
    let m = setZero(date.getMinutes()) + ':';
    let s = setZero(date.getSeconds());
    return Y+M+D+h+m+s;
})