import { get, post } from '@utils/aRequestFather'
import { baseUrl } from '@/environment/index.js'


export default {
  // 获取微信公众号授权功能配置
  getWxConfig (fullUrl) {
    const url = baseUrl + `/wx/jsApiSignature?fullUrl=${fullUrl}`
    return get(url)
  }
}