/*
 * @Descripttion:
 * @Author: NoYo
 * @Date: 2020-12-24 08:51:28
 * @LastEditTime: 2020-12-24 14:23:11
 */
import Vue from "vue";
import { Dialog } from "vant";
import router from "@/router";
import { hostUrl, baseUrl, fullUrl } from "@/environment/index.js";

export function setUrlHeader(url, type = 1) {
  var title = "";
  // if (baseUrl == "/mobile-backend") {
  //   title =
  //     "https://axz.youlove.com.cn/mobile-frontend/#" + url
  // } else {
  //   title =
  //     "http://weixin.youlove.com.cn/mobile-frontend/#" + url
  // }
  // title =
  //     "http://weixin.youlove.com.cn/mobile-frontend/#" + url
  if (type == 1) {
    title = fullUrl + url;
  } else {
    title = hostUrl + "/#" + url;
  }
  window.location.href = title;
}

export function goUrl(url) {
  var title = hostUrl + url;
  window.location.href = title;
}
export function checkUserState() {
  let state = 0; // 默认没有实名验证过
  let roleData = JSON.parse(localStorage.getItem("roleData"));

  if (roleData) {
    if (roleData.userPO.phone) {
      if (roleData.userPO.rlsbStatus == 1) {
        if (roleData.xiaoqu.length > 0) {
          var xiaoquArr = roleData.xiaoqu.filter(
            item =>
              item.userZoneRelationPO.addrFlag === 1 &&
              item.userZoneRelationPO.status === 2
          ); // 已通过并设置为默认房屋的数据
          var xiaoquCheck = roleData.xiaoqu.filter(
            item =>
              item.userZoneRelationPO.status === 2 &&
              item.userZoneRelationPO.cancel === 0
          ); // 审核通过并未注销的房屋
          var xiaoqu =
            xiaoquArr.length !== 0
              ? xiaoquArr[0]
              : xiaoquCheck.length !== 0
                ? xiaoquCheck[0]
                : null;
          var noCheck = roleData.xiaoqu.filter(
            item =>
              item.userZoneRelationPO.status === 1 &&
              item.userZoneRelationPO.cancel === 0
          ); // 有房屋但未审核
          var noCompleteZone = roleData.xiaoqu.filter(
            item =>
              item.userZoneRelationPO.status === 0 &&
              item.userZoneRelationPO.cancel === 0
          );
          // 有房屋但未完善
          var noPassZone = roleData.xiaoqu.filter(
            item =>
              item.userZoneRelationPO.status === 3 ||
              item.userZoneRelationPO.cancel === 1
          ); // 有房但未通过或已注销
          // console.log(roleData, xiaoquCheck, xiaoqu, noCheck, noCompleteZone, noPassZone, 'noPassZone')
          if (xiaoqu) {
            state = 4; // 有住房且通过审核
          } else if (noCheck.length > 0) {
            state = 5; // 有住房但是未审核
          } else if (noCompleteZone.length > 0) {
            // 有房子但未完善
            state = 3;
          } else if (noPassZone.length > 0) {
            // 有房但未通过或已注销
            state = 3;
          }
        } else {
          state = 3; // 实名过没房子
        }
      } else {
        state = 2; // 未实名认证过
      }
    } else {
      state = 1; // 手机未验证过
    }
  }
  console.log(roleData, "content");
  // alert(state, 'content')
  if (state > 3) {
    return true;
  } else if (state == 3) {
    if (localStorage.getItem("zoneId") === "2c9280827477949e01747c32106c0000") {
      return true;
    } else {
      Vue.prototype.$toastModal({
        content:
          "通行码便于扫码通行，一次注册，永久便捷，请按登记流程规范填写，谢谢！",
        stype: 2
      });
      return false;
    }
  } else {
    realNameDia(state);
    return false;
  }
}

export function realNameDia(state) {
  if (state === 5) {
    Dialog.alert({
      title: "欢迎你加入在线社区",
      message: "你有正在审核中的住房信息，请耐心等候！"
    }).then(() => { });
  } else {
    Vue.prototype.$toastModal();
    // Dialog.confirm({
    //     title: "欢迎你加入在线社区",
    //     message: "为了确保所有功能正常使用,请完成实名认证流程，谢谢！",
    //   })
    //   .then(() => {
    //     router.push("/realNameAuthentication"); //去实名认证
    //   })
    //   .catch(() => {});
  }
}
