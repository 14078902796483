/*
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-14 10:28:24
 * @LastEditTime: 2024-06-17 16:45:58
 */
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import axios from 'axios';
import router from "./router";
import store from "./store";
import cookie from 'vue-cookie'
import qs from 'qs'
import wx from 'weixin-js-sdk' // 使用npm
import Vant from 'vant'; //vant ui框架
import VConsole from 'vconsole';
// import 'lib-flexible'  //添加lib-flexible后效果（根节点会根据页面视口变化而变化font-size大小）
import '@utils/setRem'; // 初始化设置html-》font-size 控制rem
import '@utils/filter' // 全局引入 过滤器
import '@mock/mock' // 全局引入 mock
import * as tools from '@/utils/tools.js';
import * as globalFun from '@/utils/globalFun.js';
import VueBus from '@/utils/vue-bus'
import 'vant/lib/index.css';
import "./styles.less";
import toastModal from '@/components/toastModal'
Vue.prototype.$toastModal = toastModal.install;
import {
  VueJsonp
} from 'vue-jsonp'
import { APPID } from '@/environment/index.js'

import {
  Lazyload,
  DropdownMenu,
  DropdownItem
} from 'vant';

// import { from } from "core-js/fn/array";
Vue.use(Vant);
Vue.use(Lazyload);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(VueJsonp)
Vue.use(VueBus)
Vue.config.ignoredElements = [
  'wx-open-launch-app',
  'wx-open-launch-weapp'
]

// if (APPID != "wx141d83883951306e") { // 不是正式的时候 打开 vConsole
  // var vConsole = new VConsole()
// }

Vue.prototype.$http = axios
Vue.prototype.$tools = tools;
Vue.prototype.$globalFun = globalFun;
Vue.prototype.$cookie = cookie;
Vue.prototype.$Qs = qs
Vue.prototype.$wx = wx;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");