/*
 * @Descripttion:
 * @Author: NoYo
 * @Date: 2020-12-09 15:14:06
 * @LastEditTime: 2020-12-18 15:57:55
 */
import community from "./community.api";
import realNameAuthenticationApi from "./realNameAuthentication.api";
import businessApi from "./business.api";
import commonApi from "./common.api";
import hyy from "./hyy.api";
const api = {
  ...commonApi,
  ...realNameAuthenticationApi,
  ...businessApi,
  ...community,
  ...hyy
};
export default api;
