import { get, post } from "@utils/aRequestFather";
import { baseUrl } from "@/environment/index.js";

export default {
  // 最新消息列表
  getAnnouncementList(data) {
    const url = baseUrl + "/announcement/list";
    return get(url, data);
  },
  // 获取页面内容
  getAnnouncementMore(data) {
    const url = baseUrl + "/announcement/getOne";
    return get(url, data);
  },
  // 首页轮播图
  carouselChartList(data) {
    const url = baseUrl + "/carouselChart/list";
    return get(url, data);
  },
  // 首页消息轮播
  listNew(data) {
    const url = baseUrl + "announcement/listNew";
    return get(url, data);
  }
};
