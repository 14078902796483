import Vue from "vue";
import axios from "axios";
import router from "../router";
import { Toast } from "vant";
// import store from '@/store'
import { VueAxios } from "./axios";
// import {
//     ACCESS_TOKEN
// } from '@/store/mutation-types'

// 创建 axios 实例

// console.log("axiosaxiosaxiosaxiosaxiosaxiosaxiosaxiosaxiosaxiosaxiosaxiosaxiosaxios")
// console.log(localStorage.getItem("sessionId"))
const service = axios.create({
  // baseURL: '/api', // api base_url
  baseURL: "", // api base_url
  timeout: 15000, // 请求超时时间
  withCredentials: true
  // headers: {
  //     'token': 'Bearer',
  //     'Session-Id': localStorage.getItem("sessionId") ? localStorage.getItem("sessionId") : "123",
  //     "Cookie": 'Session-Id='
  // }
});

const err = error => {
  console.log(error);

  if (error.response) {
    const data = error.response.data;
    if (error.response.status === 403) {
    }
    if (error.response.status === 401) {
      // if (token) {
      //     store.dispatch('Logout1').then(() => {
      //         setTimeout(() => {
      //             window.location.reload()
      //         }, 1500)
      //     })
      // }
    }
  }
  return Promise.reject(error);
};

// request interceptor
service.interceptors.request.use(config => {
  // if (token) {
  //     config.headers['Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  // }
  return config;
}, err);

// response interceptor
service.interceptors.response.use(response => {
  // console.log(response)

  if (response.data.code != "SUCCESS") {
    // console.log(response.data.message)
    if (response.data.message) {
      Toast(response.data.message);
    }
  }

  if (response.data instanceof Blob) {
    return response;
  } else {
    return response.data;
  }
}, err);

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service);
  }
};

export { installer as VueAxios, service as axios };
