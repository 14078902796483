/*
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-14 10:28:24
 * @LastEditTime: 2024-06-17 16:49:07
 */
import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from 'nprogress'; //进度条

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
  path: "/",
  name: "",
  redirect: "/index",
  component: () => import("../App.vue"),
  children: []
},
{
  path: "/index",
  name: "Index",
  redirect: "/main",
  component: () => import("../views/Footer.vue"), // 统一设置底部导航
  children: [{
    path: "/test",
    component: () => import("../views/test/Test.vue"),
    name: "Test",
    meta: {
      title: "测试",
      icon: "dashboard",
    },
  },
  {
    path: "/main",
    component: () => import("../views/main/Main.vue"),
    name: "Main",
    meta: {
      title: "首页",
      icon: "dashboard",
      auth: false, // 是否需要登录
      keepAlive: false // 是否缓存组件
    },
  }, {
    path: "/my",
    component: () => import("../views/my/My.vue"),
    name: "my",
    meta: {
      title: "我的",
      icon: "dashboard",
    }
  },
  ]
},

// 实名认证 提交
{
  path: "/realNameAuthentication",
  component: () => import("../views/realNameAuthentication/layout.vue"),
  name: "realNameAuthentication",
  meta: {
    title: "实名认证",
  }
},
// 实名认证 结果
{
  path: "/realNameAuthenticationResult",
  component: () => import("../views/realNameAuthentication/result.vue"),
  name: "realNameAuthenticationResult",
  meta: {
    title: "实名认证",
  }
},
{
  path: "/code",
  component: () => import("../views/code/code.vue"),
  name: "code",
  meta: {
    title: "通行码",
  }
},
{
  path: "/searchCode",
  component: () => import("../views/code/searchCode.vue"),
  name: "searchCode",
  meta: {
    title: "好用点点",
  }
},
{
  path: "/parentCode",
  component: () => import("../views/code/parentCode.vue"),
  name: "parentCode",
  meta: {
    title: "接送码",
  }
},
// *** login ***
{
  path: "/login",
  name: "Login",
  component: () =>
    import( /* webpackChunkName: "about" */ "../views/login/Login.vue")
},

// *** my ***
{
  path: '/my/more',
  name: "More",
  meta: {
    title: "更多服务",
  },
  component: () => import("../views/my/More.vue"),
},
{
  path: '/my/businessList',
  name: "BusinessList",
  meta: {
    title: "商家列表",
  },
  component: () => import("../views/my/BusinessList.vue"),
},
{
  path: '/my/businessCheckIn',
  name: "More",
  meta: {
    title: "商家入驻",
  },
  component: () => import("../views/my/BusinessCheckIn.vue"),
},
{
  path: '/my/shopsCheckIn',
  name: "More",
  meta: {
    title: "商铺入驻",
  },
  component: () => import("../views/my/ShopsCheckIn.vue"),
},
// 充值
{
  path: '/cz', // 未匹配到路由时重定向
  name: "cz",
  meta: {},
  component: () => import("../views/cz/cz.vue"),
},
// 政务服务
{
  path: '/government', // 未匹配到路由时重定向
  name: "government",
  meta: {
    title: "政务服务",
  },
  component: () => import("../views/government/government.vue"),
},
// 社区百事通
{
  path: '/community', // 未匹配到路由时重定向
  name: "community",
  meta: {
    title: "社区百事通",
  },
  component: () => import("../views/community/community.vue"),
},
// 最新消息
{
  path: '/latest/news', // 未匹配到路由时重定向
  name: "news",
  meta: {
    title: "最新消息",
  },
  component: () => import("../views/latest/news.vue"),
},
// 小区公告
{
  path: '/latest/detail', // 未匹配到路由时重定向
  name: "detail",
  meta: {
    title: "小区公告",
  },
  component: () => import("../views/latest/detail.vue"),
},
// *** goods ***
{
  path: '/goods/BuyRecord',
  name: "BuyRecord",
  meta: {
    title: "商品购买记录",
  },
  component: () => import("../views/goods/BuyRecord.vue"),
},
{
  path: '/goods/BuyRecordDetail',
  name: "BuyRecordDetail",
  meta: {
    title: "商品购买记录详情",
  },
  component: () => import("../views/goods/BuyRecordDetail.vue"),
},
{
  path: '/goods/BuyRegister',
  name: "BuyRegister",
  meta: {
    title: "商品购买登记",
  },
  component: () => import("../views/goods/BuyRegister.vue"),
},
{
  path: '/goods/BuyRegisterType2',
  name: "BuyRegisterType2",
  meta: {
    title: "商品购买登记",
  },
  component: () => import("../views/goods/BuyRegisterType2.vue"),
},
{
  path: '/goods/BuyRegisterOver',
  name: "BuyRegisterOver",
  meta: {
    title: "商品购买记录",
  },
  component: () => import("../views/goods/BuyRegisterOver.vue"),
},

{
  path: '/bbszy', // 中医中转
  name: "",
  meta: {},
  component: () => import("../views/zzy/bbszzy.vue"),
},
{
  path: '/djszy', // 中医中转
  name: "",
  meta: {},
  component: () => import("../views/zzy/djzzy.vue"),
},
{
  path: '/zyzz', // bbs中转
  name: "",
  meta: {},
  component: () => import("../views/zzy/zyzz.vue"),
},
{
  path: '*', // 未匹配到路由时重定向
  name: "error",
  meta: {},
  component: () => import("../views/error.vue"),
},

];

const router = new VueRouter({
  routes
});

// 判断是否需要登录权限 以及是否登录
router.beforeEach((to, from, next) => {
  NProgress.start();

  // if (to.path === "/login") {
  //   next();
  //   return;
  // }
  // let sessionId = localStorage.getItem("sessionId")
  // console.log("sessionId" + sessionId)
  // if (!sessionId) {
  //   next({
  //     path: '/login',
  //   })
  // } else {
  //   next()
  // }

  next()
})


router.afterEach((to) => {
  NProgress.done();
  document.title = to.meta.title || '好用点点'
  // console.log("scrollToscrollToscrollToscrollToscrollTo2")
  window.scroll(0, 0)
});


export default router;