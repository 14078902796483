/*
 * @Descripttion:
 * @Author: NoYo
 * @Date: 2020-12-16 14:50:36
 * @LastEditTime: 2022-02-17 17:14:12
 */
import { get, post } from "@utils/aRequestFather";
import { baseUrl } from "@/environment/index.js";

export default {
  // 服务电话列表
  siteServingPage(data) {
    const url = `/xcx/siteServing/siteServingPage`;
    return post(url, data);
  },
  // 页面访问次数增加
  visitTimes(data) {
    const url = `/xcx/siteServing/visitTimes`;
    return post(url, data);
  },
  // 手机号拨打次数增加
  addPhoneTimes(data) {
    const url = `/xcx/siteServing/addPhoneTimes`;
    return post(url, data);
  }
};
