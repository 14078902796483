let urls = {
  deptApply: {
    // 申请开通 (fkm)
    oldUrl: "/pages/home/dept-apply/dept-apply",
    serUrl: "/#/?needLink=deptApply"
  },
  visitorinvitedList: {
    // 邀请列表
    oldUrl: "/pages/house-owner/visitor-invited/visitor-invited-list",
    serUrl: "/#/?needLink=visitorinvitedList"
  },
  checkDetail: {
    // 访客审核页面 (fkshym)
    oldUrl: "/pages/home/mine/check-detail?isNotice=1&id=",
    serUrl: "/#/?needLink=checkDetail&id=",
    paramStr: "id"
  },
  visitDetail: {
    // 访问详情 (fklb)
    oldUrl: "/pages/home/mine/visit-detail?id=",
    serUrl: "/#/?needLink=visitDetail&id=",
    paramStr: "id"
  },
  yzMyRelationApprove: {
    // 租客/家属审核（业主查看） (zklb/jslb)
    oldUrl: "/pages/house-owner/my-relation-approve?isFromMessage=1&id=",
    serUrl: "/#/?needLink=yzMyRelationApprove&id=",
    paramStr: "id",
    params: ["hzid"]
  },
  zkMyRelationApprove: {
    // 房屋审核查看（租客或家属查看） (fwlb)
    oldUrl: "/pages/house-owner/my-relation-approve?id=",
    serUrl: "/#/?needLink=zkMyRelationApprove&id=",
    paramStr: "id"
  },
  myDetail: {
    // 房屋审核完查看 (grzx)  修改跳转 my-detail页面
    oldUrl: "/pages/home/mine/my-detail",
    serUrl: "/#/?needLink=myDetail"
  },
  marketCheckDetail: {
    // 好货抢购审核详情 (eswpxq)
    oldUrl: "/pages/home/market-check/market-check-detail?topicId=",
    serUrl: "/#/?needLink=marketCheckDetail&topicId=",
    paramStr: "topicId"
  },
  secondaryDetail: {
    // 好货抢购详情 (esxq)
    oldUrl: "/pages/disconver/bbs/secondary-market/secondary-detail?topicId=",
    serUrl: "/#/?needLink=secondaryDetail&topicId=",
    paramStr: "topicId"
  },
  entrustTeacherDetail: {
    // 委托接送详情（教师查看） (wtjs)
    oldUrl: "`/pages/home/grade/entrust-teacher-detail?id=",
    serUrl: "/#/?needLink=entrustTeacherDetail&id=",
    paramStr: "id"
  },
  groupDetail: {
    // 参加拼团详情 (cjpt)
    oldUrl: "/pages/home/group-booking/group-detail?type=&id=",
    serUrl: "/#/?needLink=groupDetail&id=",
    paramStr: "id"
  },
  visitHistory: {
    // 访问记录 (fkjl)
    oldUrl: "/pages/home/mine/visit-history",
    serUrl: "/#/?needLink=visitHistory"
  },
  securityItemDetail: {
    // 安全防范详情 (aqff)
    oldUrl: "/pages/component/bbs/security-item-detail?id=",
    serUrl: "/#/?needLink=securityItemDetail&id=",
    paramStr: "id"
  },
  // 以下分享页
  visitorInvitedQrcode: {
    // 邀请码页面分享
    oldUrl:
      "/pages/house-owner/visitor-invited/visitor-invited-qrcode?type=used&id=",
    serUrl: "/#/?needLink=visitorInvitedQrcode&id=",
    paramStr: "id"
  },
  entrustQrcode: {
    // 委托接送码页面分享
    oldUrl: "/pages/home/grade/entrust-qrcode?id=",
    serUrl: "/#/?needLink=entrustQrcode&id=",
    paramStr: "id"
  },
  invitedParent: {
    // 家长资料页面分享
    oldUrl: "/pages/home/grade/invited-parent",
    serUrl: "/#/?needLink=invitedParent"
  },
  securityItemDetail: {
    // 安全防范详情页面分享
    oldUrl: "/pages/component/bbs/security-item-detail?id=",
    serUrl: "/#/?needLink=securityItemDetail&id=",
    paramStr: "id"
  },
  bbsDetail: {
    // 大家聊详情页面分享
    oldUrl: "/pages/component/bbs/bbs-detail?topicId=",
    serUrl: "/#/?needLink=bbsDetail&topicId=",
    paramStr: "topicId"
  },
  localLifeDetail: {
    // 本地活动详情页面分享
    oldUrl: "/pages/component/local-life-detail?id=",
    serUrl: "/#/?needLink=localLifeDetail&id=",
    paramStr: "id"
  },
  employmentDetail: {
    // 招聘详情页面分享
    oldUrl: "/pages/disconver/bbs/employment/employment-detail?topicId=",
    serUrl: "/#/?needLink=employmentDetail&topicId=",
    paramStr: "topicId"
  },
  carDetail: {
    // 车位出租详情页面分享
    oldUrl: "/pages/disconver/bbs/rent/car/detail?topicId=",
    serUrl: "/#/?needLink=carDetail&topicId=",
    paramStr: "topicId"
  },
  houseDetail: {
    // 房屋出租详情页面分享
    oldUrl: "/pages/disconver/bbs/rent/house/detail?topicId=",
    serUrl: "/#/?needLink=houseDetail&topicId=",
    paramStr: "topicId"
  },
  rentShopDetail: {
    // 商铺出租详情页面分享
    oldUrl: "/pages/disconver/bbs/rent/shop/detail?topicId=",
    serUrl: "/#/?needLink=rentShopDetail&topicId=",
    paramStr: "topicId"
  },
  shareGroupDetail: {
    // 拼团详情页面分享
    oldUrl: "/pages/home/group-booking/group-detail?type=&share=1&id=",
    serUrl: "/#/?needLink=shareGroupDetail&id=",
    paramStr: "id"
  },
  shopEnter: {
    // 商家入驻页面分享
    oldUrl: "/pages/home/shop/shop-enter",
    serUrl: "/#/?needLink=shopEnter"
  },
  shopDetail: {
    // 商家详情页面分享
    oldUrl: "/pages/home/shop/shop-detail?id=",
    serUrl: "/#/?needLink=shopDetail&id=",
    paramStr: "id"
  }
};

export { urls };
