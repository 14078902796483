<template>
  <div class="toast-modal" v-if="visible">
    <div class="modal">
      <div class="title">欢迎您注册好用点点</div>
      <div class="content">{{ content }}</div>
      <div class="footer">
        <div @click="handelCancel">取消</div>
        <div>
          <wx-open-launch-weapp
            style="baoliao"
            :id="miniId"
            :username="miniId"
            :path="
              stype == 1
                ? 'pages/authorization?path=my/apply/realName'
                : stype == 3
                ? 'pages/authorization?path=my/visitorYq/index'
                : 'pages/authorization?path=my/address/index'
            "
          >
            <script type="text/wxtag-template">
              <style>
                  .text{
                      color: red;
                      font-size: 16px;
                      text-align: center;
                      height: 46px;
                      line-height: 46px;
                  }
              </style>
              <div class="text">确定</div>
            </script>
          </wx-open-launch-weapp>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      miniId: "gh_67be8caedeb9",
      stype: 1,
      content: "为了确保所有功能正常使用,请完成实名认证流程，谢谢！"
    };
  },
  methods: {
    handelCancel() {
      this.visible = false;
      this.$emit("cancelZ");
    },
    open(
      content = "为了确保所有功能正常使用,请完成实名认证流程，谢谢！",
      stype = 1
    ) {
      this.visible = true;
      this.content = content;
      this.stype = stype;
    }
  }
};
</script>
<style scoped lang="less">
.toast-modal {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #666;
  .modal {
    width: 80%;
    background: #fff;
    border-radius: 8px;
    text-align: center;
    padding-top: 15px;
  }
  .title {
    color: #000;
    margin-bottom: 10px;
  }
  .content {
    font-size: 12px;
    margin-bottom: 20px;
    padding: 0 25px;
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    width: 100%;
    border-top: 1px solid #efefef;
    color: red;
    font-size: 16px;
    div {
      flex: 1;
      height: 100%;
      line-height: 46px;
    }
    div:first-child {
      border-right: 1px solid #efefef;
      color: #000;
    }
  }
}
</style>
