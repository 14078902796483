/*
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-18 09:35:13
 * @LastEditTime: 2024-06-17 16:29:25
 */
// 本地环境 
module.exports = {
  hostUrl: "https://axz.youlove.com.cn",
  fullUrl: "https://axz.youlove.com.cn/mobile-frontend/#",
  baseUrl: "/mobile-backend",
  APPID: "wx141d83883951306e"
}