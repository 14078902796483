/*
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-16 14:50:36
 * @LastEditTime: 2022-02-17 17:14:12
 */
import {
  get,
  post
} from '@utils/aRequestFather'
import { baseUrl } from '@/environment/index.js'

export default {
  // 获取职业数据 common/topJob  opst
  getJobData() {
    const url = baseUrl + '/common/topJob'
    return get(url)
  },
  // 获取手机验证码
  getVerifyCode(data) {
    const url = baseUrl + '/user/yzm'
    return get(url, data)
  },
  // 个人信息提交 user/updateUserPhone  post   phone  yzm jobCode  jobName  topJobCode topJobName
  savePersonal(data) {
    const url = baseUrl + '/user/updateUserPhone'
    return post(url, data)
  },
  // 实名信息提交 user/rlsb  post  username  certificateContent  image
  saveRealName(data) {
    const url = baseUrl + '/user/rlsb'
    return post(url, data)
  },
  // 获取省市县数据
  getAreaList() {
    const url = baseUrl + '/common/xzqh'
    return post(url)
  },
  // 获取街道数据
  getStreetList(areaCode) {
    const url = baseUrl + `/common/town?areaCode=${areaCode}`
    return post(url)
  },
  // 获取社区数据
  getCommunityList(townCode) {
    const url = baseUrl + `/common/village?townCode=${townCode}`
    return post(url)
  },
  // 获取小区数据
  getXqList(communityCode) {
    const url = baseUrl + `/userZoneRelation/getZone?communityCode=${communityCode}`
    return get(url)
  },
  // 居住信息提交
  saveLive(data) {
    const url = baseUrl + `/userZoneRelation/createOrUpdate`
    return post(url, data)
  },
  // 充值
  rechargePhoneOrder(data) {
    const url = `/xcx/rechargePhoneOrder/placeOrder`
    return post(url, data)
  },
  // 充值折扣
  getCurrentDiscount() {
    const url = `/xcx/rechargePhoneOrder/getCurrentDiscount`
    return get(url)
  }
}