import {
    get,
    post
} from './aRequestFather'
import { baseUrl } from '@/environment/index.js'

// <------- 登录退出------->

//登录
// export const userLogin = params => get('/mobile-backend/user/login', params)
export const userLogin = params => get(baseUrl + '/user/login', params)
//用户信息
export const getUserInfo = params => get(baseUrl + '/user/getUserInfo', params)
//获取wx 签名   get  传参  fullUrl
export const getJsApiSignature = params => get(baseUrl + '/wx/jsApiSignature', params)


//模拟登陆
export const falseTestGetUserInfo = params => get(baseUrl + '/user/testGetUserInfo', params)

export const wxBuildAuthUrl = params => get(baseUrl + '/wx/buildAuthUrl', params)


// mian 首页

//轮播图  传参 zoneId  type
export const carouselList = params => get(baseUrl + '/carousel/list', params)

//系统公告  sysNotice/list   get   传参 pageNum  pageSize
export const sysNoticeList = params => get(baseUrl + '/sysNotice/list', params)

//安全防范  securityPrecaution/list  get  传参  pageNum  pageSize  keyword
export const securityPrecautionList = params => get(baseUrl + '/securityPrecaution/list', params)

//聊一聊    topic/weTalkList  get  传参 pageNum  pageSize  zoneId
export const topicWeTalkList = params => get(baseUrl + '/topic/weTalkList', params)

//点赞
export const topicAddLike = (params, title) => post(baseUrl + '/topic/addLike' + title, params)

// my 我的

//获取我的页面 各项数据的个数
export const userMyCount = params => get(baseUrl + '/user/myCount', params)

// business/myList     get    businessId      获取商铺列表
export const businessMyList = params => get(baseUrl + '/business/myList', params)

// business/businessVisitorList  get   userId 购买记录列表
export const businessBusinessVisitorList = params => get(baseUrl + '/business/businessVisitorList', params)

// business/goodsType     get    businessId      获取商品类列表
export const businessGoodsType = params => get(baseUrl + '/business/goodsType', params)

// business/myEdit     get    id      获取商品类关联信息
export const businessMyEdit = params => get(baseUrl + '/business/myEdit', params)

// business/saveRecords    post   businessId   goodsTypeIds  新增购买记录
export const businessSaveRecords = params => post(baseUrl + '/business/saveRecords', params)

// business/visitoredit  get  businessVisitorId 购买记录详情
export const businessVisitoredit = params => get(baseUrl + '/business/visitoredit', params)



//获取验证码
// export const getLoginAuthCode = params => get('/mszj-web/common/getLoginAuthCode', params)