import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    roleData: JSON.parse(localStorage.getItem("roleData")) || null, //登陆后获取的用户信息集合 => roleData
  },
  getters:{
    //返回当前的角色信息
    returnRoleData(state){
      // 判断当前是否存在roleData
      return state.roleData
    }
  },
  mutations: {
    MsetRoleData(state,content){
      // console.log("M")
      // console.log(state)
      // console.log(content)
      state.roleData = content
    }
  },
  actions: {
    setRoleData(state,content){
      // console.log("A")
      // console.log(state)
      // console.log(content)
      state.commit("MsetRoleData",content)
    }
  },
  modules: {}
});
