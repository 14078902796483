<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-14 10:28:24
 * @LastEditTime: 2022-02-17 14:02:23
-->
<template>
  <div id="app">
    <router-view v-if="routerViewState"></router-view>
    <!-- 开启顶部安全区适配 -->
    <!-- <van-nav-bar safe-area-inset-top /> -->

    <!-- 开启底部安全区适配 -->
    <van-number-keyboard safe-area-inset-bottom />
  </div>
</template>
<script>
import api from "@/api";
import {
  userLogin,
  falseTestGetUserInfo,
  wxBuildAuthUrl
} from "@/utils/aRequest";

import { baseUrl, APPID } from "@/environment/index.js";
import { urls } from "@/utils/urlConfig";

export default {
  data() {
    return {
      routerViewState: false
    };
  },

  created() {
    // debugger
    this.initialization();
  },

  methods: {
    //初始化 判断项目是否微信授权
    initialization() {
      var self = this;
      let code = this.getUrlCode(window.location.href, "code");
      if (localStorage.getItem("openId")) {
        // console.log(1);
        // 判断本地是否有 openId 直接获取用户信息 免登陆
        falseTestGetUserInfo({ gzhOpenId: localStorage.getItem("openId") })
          .then(data => {
            // console.log(data);
            if (data.code == "SUCCESS") {
              this.$cookie.set("sessionId", data.data.sessionId, 1); // 设置cookie
              localStorage.setItem("sessionId", data.data.sessionId);
              localStorage.setItem("openId", data.data.userPO.gzhOpenId);
              this.$store.dispatch("setRoleData", data.data); //vuex 存roleData 信息
              localStorage.setItem("roleData", JSON.stringify(data.data)); //本地缓存roleData信息
              self.routerViewState = true;
              self.initWx(); // 初始化微信功能
              self.isLink(); //判断是否跳转
            } else {
              this.clearAndLogin();
            }
          })
          .catch(() => {
            this.clearAndLogin();
          });
      } else {
        if (code && code != null && code != "") {
          // 判断微信授权后得到的code 做登陆
          userLogin({ code: code }).then(data => {
            if (data.code == "SUCCESS") {
              this.$cookie.set("sessionId", data.data.sessionId, 1); // 设置cookie
              localStorage.setItem("sessionId", data.data.sessionId);
              localStorage.setItem("openId", data.data.userPO.gzhOpenId);
              this.$store.dispatch("setRoleData", data.data); //vuex 存roleData 信息
              localStorage.setItem("roleData", JSON.stringify(data.data)); //本地缓存roleData信息
              self.routerViewState = true;
              self.initWx(); // 初始化微信功能
              self.isLink(); //判断是否跳转
            } else {
              // 地址存在code 但是没有缓存openId  此时登陆失败  直接刷新页面重新授权
              let url = self.funcUrlDel("code");
              self.goLogin(url);
            }
          });
        } else {
          this.clearAndLogin();
        }
      }
    },
    clearAndLogin() {
      localStorage.removeItem("sessionId");
      localStorage.removeItem("openId");
      localStorage.removeItem("roleData");
      localStorage.removeItem("zoneId");
      localStorage.removeItem("localCode"); // 设置code
      this.goLogin(); //去登录
    },

    isLink() {
      let needLink = this.getUrlCode(window.location.href, "needLink");
      if (needLink && localStorage.getItem("openId")) {
        if (urls[needLink].paramStr) {
          let url =
            urls[needLink].oldUrl +
            this.getUrlCode(window.location.href, urls[needLink].paramStr);
          if (urls[needLink].params) {
            let params = urls[needLink].params
              .map(
                item => `${item}=${this.getUrlCode(window.location.href, item)}`
              )
              .join("&");
            this.$globalFun.setUrlHeader(url + "&" + params);
          } else {
            this.$globalFun.setUrlHeader(url);
          }
        } else {
          this.$globalFun.setUrlHeader(urls[needLink].oldUrl);
        }
      }
      return;
    },

    getUrlCode(title, name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            title
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },

    funcUrlDel(name) {
      var loca = window.location;
      var baseUrl = loca.origin + loca.pathname + "?";
      var query = loca.search.substr(1);
      if (query.indexOf(name) > -1) {
        var obj = {};
        var arr = query.split("&");
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].split("=");
          obj[arr[i][0]] = arr[i][1];
        }
        delete obj[name];
        var url =
          baseUrl +
          JSON.stringify(obj)
            .replace(/[\"\{\}]/g, "")
            .replace(/\:/g, "=")
            .replace(/\,/g, "&");
        return url;
      } else {
        return window.location.href;
      }
    },

    goLogin(url) {
      // var APPID = "";
      // if (baseUrl == "/mobile-backend") {
      //   APPID = "wx141d83883951306e"; // 正式
      // } else {
      //   APPID = "wx64f5f6f58462089a"; // 非正式（本地测试+线上测试）
      // }
      let redirect_uri = url
        ? encodeURIComponent(url)
        : encodeURIComponent(window.location.href);
      // : encodeURIComponent("http://weixin.youlove.com.cn");
      let scope = "snsapi_userinfo"; //snsapi_base（不弹出授权页面，直接跳转，只能获取用户openid），snsapi_userinfo 弹出授权页面
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=123#wechat_redirect`;
      return;

      // let da = {
      //   redirectURI: encodeURIComponent(window.location.href),
      //   scope: "snsapi_userinfo",
      // };
      // wxBuildAuthUrl(da).then((data) => {
      //   console.log(data);
      //   if (data.code == "SUCCESS") {
      //     window.location.href = data.data;
      //   }
      // });
    },

    initWx() {
      // console.log("initWxinitWxinitWxinitWx------------");
      let apiList = [
        "closeWindow",
        "scanQRCode",
        "checkJsApi",
        "updateAppMessageShareData",
        "updateTimelineShareData",
        "onMenuShareTimeline",
        "onMenuShareQQ",
        "onMenuShareWeibo",
        "onMenuShareQZone",
        "chooseImage",
        "uploadImage",
        "previewImage",
        "getLocalImgData",
        "startRecord",
        "stopRecord",
        "uploadVoice",
        "getLocation",
        "chooseWXPay"
      ];
      let tagList = ["wx-open-launch-weapp"];
      api
        .getWxConfig(encodeURIComponent(location.href.split("#")[0]))
        .then(res => {
          // console.log("configconfigconfigconfigconfigconfigconfig");
          // console.log(res);
          var config = res.data;
          this.$wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: config.appId, // 必填，公众号的唯一标识
            timestamp: config.timestamp, // 必填，生成签名的时间戳
            nonceStr: config.nonceStr, // 必填，生成签名的随机串
            signature: config.signature, // 必填，签名
            jsApiList: apiList, // 必填，需要使用的JS接口列表
            openTagList: tagList || [] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
          });
          this.$wx.ready(() => {
            console.log("wx-base-ready");
          });
          this.$wx.error(function (error) {
            console.log(error);
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
          });
        });
    }
  },
  mounted() { }
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-y: auto;
}

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
